import React, { useState, useEffect, useMemo } from 'react';
import { Button, Tab, Row, Col, Nav, Modal } from 'brickyard-ui';
import { useQuery } from 'react-apollo';

import RequestExemptionButton from './RequestExemptionButton';
import RelatedUseCaseResultListItem from './RelatedUseCaseResultListItem';
import UseCaseResultRelationHeader from './UseCaseResultRelationHeader';
import UseCaseResultTab from './UseCaseResultTab';
import { GET_EXEMPTIONS } from '../queries/cop/exemptions/exemptions';
import { useSelector } from 'react-redux';
import ResetUseCaseResult from './ResetUseCaseResult';

const EXEMPTION_RESULT_APPROVED = 'approved';
const EXEMPTION_RESULT_DENIED = 'denied';

const getObservationList = mainObservation => {
  const { status, relatedUseCaseResults } = mainObservation;
  const observations = status === 'validated' ? [] : relatedUseCaseResults;
  const mainObsIndex = observations.findIndex(obs => obs.id === mainObservation.id);
  if (mainObsIndex !== -1) {
    // mainObservation contains the related observations list
    observations.splice(mainObsIndex, 1, mainObservation);
  } else {
    const index = observations.findIndex(
      obs => new Date(obs.observedAt).getTime() > new Date(mainObservation.observedAt).getTime()
    );
    index ? observations.splice(index, 0, mainObservation) : observations.push(mainObservation);
  }
  return observations;
};

const UseCaseResultTabs = ({
  mainObservation,
  getObsStatus,
  getObsRelatedStatus,
  onRelationChange,
  markTo,
  onReset,
  useCase,
  type
}) => {
  const [observationList, setObservationList] = useState([]);
  const filters = useSelector(state => state.observations.filters);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [selectedRelatedItem, setSelectedRelatedItem] = useState(mainObservation);

  const selectedObservationIncluded = observations => {
    const found = observations.find(obs => obs.id === selectedRelatedItem.id);

    return !!found;
  };

  const closeModal = () => {
    setShowConfirmModal(false);
  };

  const onUndo = () => {
    setShowConfirmModal(true);
  };

  useEffect(() => {
    setObservationList(getObservationList(mainObservation));
  }, [mainObservation]);

  const isNotMain = obs => obs.id !== mainObservation.id;

  const canUseCaseResultBeEdited =
    mainObservation.useCaseResultPermission == 'USE_CASE_RESULT_PERMISSION_WRITE' &&
    mainObservation.canBeEvaluatedInBackoffice;

  const relateAll = () => {
    const observations = getObservationList(mainObservation).filter(isNotMain);

    onRelationChange('relate', observations);
  };

  useEffect(() => {
    if (useCase.organisation.linkRelatedObservations) {
      relateAll();
    }
  }, [useCase.organisation.linkRelatedObservations]);

  const unrelateAll = () => {
    const observations = getObservationList(mainObservation).filter(isNotMain);

    onRelationChange('unrelate', observations);
  };

  const deleteAll = () => {
    const observations = getObservationList(mainObservation);
    onRelationChange('delete', observations);
  };

  const deleteUnrelated = () => {
    const observations = getObservationList(mainObservation).filter(
      obs => isNotMain(obs) && getObsStatus(obs) !== 'related'
    );
    onRelationChange('delete', observations);
    if (selectedObservationIncluded(observations)) {
      selectMainObservation();
    }
  };

  const selectMainObservation = () => {
    document.querySelector('a[data-rb-event-key="' + mainObservation.id + '"]').click();
  };

  const exemptionRequestSearch = { licensePlate: mainObservation.vehicleLicensePlateNumber };
  const { data: exemptionsData, loading: exemptionsLoading } = useQuery(GET_EXEMPTIONS, {
    variables: { exemptionSearch: exemptionRequestSearch, requestSearch: exemptionRequestSearch }
  });

  const shouldShowExemptionButton = useMemo(() => {
    if (mainObservation.blueZone == true && selectedRelatedItem.evidence == true) {
      return false;
    }
    if (!useCase.exemptionEnabled) return false;
    if (mainObservation.status === 'marked_for_exemption' || mainObservation.exempt) {
      return false;
    }
    if (!exemptionsLoading) {
      const { exemptionRequests, exemptionsList } = exemptionsData;

      if (exemptionRequests.length > 0) {
        return false;
      }

      let hasApprovedExemption = false;
      exemptionsList.forEach(exemption => {
        if (exemption.result === EXEMPTION_RESULT_APPROVED) {
          if (exemption.copExemptionType.global) {
            hasApprovedExemption = true;
          } else if (parseInt(exemption.copUseCase.id) === parseInt(mainObservation.useCaseId)) {
            hasApprovedExemption = true;
          }
        }
      });

      if (hasApprovedExemption) return false;
    } else {
      return false;
    }

    return true;
  }, [mainObservation.id, selectedRelatedItem.id, exemptionsData]);

  const shouldDisableExemptionButton = useMemo(() => {
    let showAsDisabled = false;

    if (!exemptionsLoading) {
      const { exemptionsList } = exemptionsData;

      exemptionsList.forEach(exemption => {
        if (exemption.result === EXEMPTION_RESULT_DENIED) {
          if (exemption.copExemptionType.global) {
            showAsDisabled = true;
          } else if (parseInt(exemption.copUseCase.id) === parseInt(mainObservation.useCaseId)) {
            showAsDisabled = true;
          }
        }
      });
    }

    return showAsDisabled;
  }, [mainObservation.id, exemptionsData]);

  return (
    <Tab.Container
      id="observation-tabs"
      defaultActiveKey={mainObservation.id}
      mountOnEnter
      unMountOnExit
    >
      <Row noGutters className="full-height">
        <Col className="full-height related-uc-section">
          <UseCaseResultRelationHeader
            relateAll={relateAll}
            unrelateAll={unrelateAll}
            deleteAll={deleteAll}
            deleteUnrelated={deleteUnrelated}
            info={mainObservation.camera.name}
            useCaseName={mainObservation.useCase.name}
            canUseCaseResultBeEdited={canUseCaseResultBeEdited}
          />
          <div className="list-container">
            {!filters.validatedFilter && (
              <Nav variant="by-vertical">
                {observationList
                  .filter(o => !(o.evidence == true && o.linkedUcrId == null))
                  .map(o => (
                    <Nav.Item key={o.id}>
                      <Nav.Link eventKey={o.id}>
                        <RelatedUseCaseResultListItem
                          isMain={mainObservation.id === o.id}
                          useCaseResult={o}
                          status={getObsRelatedStatus(o)}
                          canUseCaseResultBeEdited={canUseCaseResultBeEdited}
                          onClick={() => {
                            setSelectedRelatedItem(o);
                          }}
                          onRelationChange={newStatus => {
                            onRelationChange(newStatus, [o]);
                            if (newStatus == 'delete') {
                              selectMainObservation();
                            }
                          }}
                        />
                      </Nav.Link>
                    </Nav.Item>
                  ))}
              </Nav>
            )}
          </div>

          <div className="observation-actions">
            {canUseCaseResultBeEdited && mainObservation.canBeEvaluatedInBackoffice && (
              <>
                {(mainObservation.status !== 'validated' || mainObservation.validatedForDeletion) &&
                !filters.validatedFilter ? (
                  <Row noGutters>
                    {!mainObservation.exempt &&
                    !mainObservation.delayed &&
                    !(mainObservation.blueZone == true && selectedRelatedItem.evidence == true) &&
                    mainObservation.ticketable ? (
                      <>
                        <Col sm={4}>
                          {mainObservation.useCase.warningEnabled && (
                            <Button variant="warning" size="sm" onClick={() => markTo('warning')}>
                              {I18n.t('observations.observations.details.to_warning')}
                            </Button>
                          )}
                        </Col>
                        <Col sm={4}>
                          <Button variant="danger" size="sm" onClick={() => markTo('ticket')}>
                            {I18n.t('observations.observations.details.to_ticket')}
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <Col sm={8} />
                    )}
                    <Col sm={4}>
                      {shouldShowExemptionButton && (
                        <RequestExemptionButton
                          onSubmit={markTo}
                          disabled={shouldDisableExemptionButton}
                        />
                      )}
                    </Col>
                  </Row>
                ) : (
                  <Row noGutters>
                    <Col sm={12}>
                      <Button variant="warning" size="md" onClick={onUndo}>
                        {I18n.t('observations.observations.details.undo_btn')}
                      </Button>
                      {showConfirmModal && (
                        <Modal show={showConfirmModal} onHide={closeModal}>
                          <Modal.Header>
                            {I18n.t('observations.observations.details.undo_description')}
                          </Modal.Header>
                          <Modal.Body>
                            {I18n.t('observations.observations.details.undo_confirmation')}
                          </Modal.Body>
                          <Modal.Footer>
                            <ResetUseCaseResult
                              useCaseResultId={mainObservation.id}
                              onReset={onReset}
                              isButton
                              recordUndo
                              validatedFilter={filters.validatedFilter}
                              defaultLabel={I18n.t('observations.observations.details.undo_btn')}
                            />
                            <Button variant="by-dark" onClick={closeModal}>
                              {I18n.t('settings.image_editor.cancel')}
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      )}
                    </Col>
                  </Row>
                )}
              </>
            )}
          </div>
        </Col>

        <div className="full-height ucr-form-section">
          <Tab.Content className="full-height">
            {observationList.map(o => (
              <UseCaseResultTab
                useCaseResult={o}
                mainObservation={mainObservation}
                canUseCaseResultBeEdited={canUseCaseResultBeEdited}
                type={type}
                key={o.id}
              />
            ))}
          </Tab.Content>
        </div>
      </Row>
    </Tab.Container>
  );
};

export default UseCaseResultTabs;
